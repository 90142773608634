<template>
  <v-row v-if="!loading">
    <span>
      <ImplicitActivitiesDialog
        :implicit-activity="implicitActivity"
        :explicit-name="explicitActivity.name"
        :disabled="disabled"
        @update="save"
      ></ImplicitActivitiesDialog>
    </span>
  </v-row>
  <v-row v-else>
    <DataLoader :custom-loading-trigger="loading"></DataLoader>
  </v-row>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import ImplicitActivitiesDialog from '@/components/activities/ImplicitActivitiesDialog.vue'
import DataLoader from '@/components/common/DataLoader.vue'

export default {
  name: 'ImplicitActivityTableCell',
  components: { DataLoader, ImplicitActivitiesDialog },
  props: {
    implicitActivity: {
      type: Object,
      required: false,
      default: null
    },
    explicitActivity: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    ...mapMutations('page', ['setDataLoadingEnabled']),
    ...mapActions('activities', [
      'updateExplicitActivity',
      'refreshImplicitActivity'
    ]),
    save(implicitActivity) {
      this.loading = true
      this.setDataLoadingEnabled(false)
      this.updateExplicitActivity({
        id: this.explicitActivity.id,
        data: {
          implicit_activity_id: implicitActivity.id
        }
      })
        .then(() => {
          this.refreshImplicitActivity({
            explicitActivityId: this.explicitActivity.id,
            implicitActivity
          })
        })
        .finally(() => {
          this.setDataLoadingEnabled(true)
          this.loading = false
        })
    }
  }
}
</script>

<style scoped></style>
